<template>
  <div ref="outputContainer" class="outputContainer">
    <div v-for="output in outputs" :key="output.key" v-bind:class="output.class">{{output.content}}</div>
  </div>
</template>

<script>
import {watch, ref, nextTick} from 'vue'
export default {
  name: "OutputNormal",
  props: {
    outputs: Array
  },
  setup(props){
    const outputContainer = ref(null)
    watch(props.outputs, () => {
      nextTick(() => {
        outputContainer.value.scrollTop = outputContainer.value.scrollHeight
      })
    })
    return {
      outputContainer
    }
  }
}


</script>

<style scoped>
.outputContainer {
  font-family: monospace;
  text-align: left;
  padding: 1em;
  max-height: 25vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.outputNormal {
}
.outputError {
  color: red;
}
</style>