<template>
  <div id="info" style='display:none'></div>
  <div id="canvas-wrapper">
    <canvas class="canvas" ref="canvas"></canvas>
    <video ref="videoElement" playsinline style="
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          visibility: hidden;
          width: auto;
          height: auto;
          position: absolute;
          ">
    </video>
  </div>
  <div id="scatter-gl-container"></div>
</template>

<script>
import * as handpose from "@tensorflow-models/handpose"
import { ref, onMounted, computed } from "vue"
import * as tfjs from '@tensorflow/tfjs'
// import '@tensorflow/tfjs-backend-webgl'
import '@tensorflow/tfjs-backend-wasm'
async function setupCamera(video) {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    throw new Error(
        'Browser API navigator.mediaDevices.getUserMedia not available');
  }

  const stream = await navigator.mediaDevices.getUserMedia({
    'audio': false,
    'video': {
      facingMode: 'user'
    },
  });
  video.srcObject = stream;

  return new Promise((resolve) => {
    video.onloadedmetadata = () => {
      resolve(video);
    };
  });
}
async function loadVideo(videoElement) {
  await setupCamera(videoElement);
  videoElement.play();
  return videoElement;
}
const landmarksRealTime = async (ctx, videoHeight, videoWidth, canvas, model, video, enabled, perceptionCallback) => {
  async function frameLandmarks() {
    ctx.drawImage( video, 0, 0, videoWidth, videoHeight, 0, 0, canvas.width, canvas.height);
    if (enabled.value){
      await tfjs.ready()
      const predictions = await model.estimateHands(video);
      if (predictions.length > 0){
        const hand = predictions[0]
        if (hand.handInViewConfidence > 0.95){
          draw(hand, ctx)
          perceptionCallback(hand)
        }
      }
    }
    requestAnimationFrame(frameLandmarks);
  }
  frameLandmarks();
};

function draw(hand, ctx){
  console.log('Found hand')
  const allPoints = Object.values(hand.annotations).reduce((a, c) => a.concat(c), [])
  const maxZ = Math.max(...allPoints.map(p => p[2]))
  const minZ = Math.min(...allPoints.map(p => p[2]))
  for (const [part, points] of Object.entries(hand.annotations)) {
    for (const [i, point] of points.entries()){
      const [x, y, z] = point
      ctx.beginPath();
      ctx.arc(point[0], point[1], (1-(z-minZ)/(maxZ - minZ)) * 10, 0, 2 * Math.PI, false);
      if (part === "thumb"){
        ctx.fillStyle = 'red';
      } else {
        ctx.fillStyle = 'green';
      }
      ctx.fill();
      ctx.lineWidth = 5;
      ctx.strokeStyle = '#003300';
      ctx.stroke();
      ctx.font = '48px serif';
      ctx.fillText(`${i}`, x, y);
    }
  }
}

// const calcGrabScore = (annotations) => {
//   var diff = 0
//   for (const [part, points] of Object.entries(annotations)) {
//     if(part !== "palmBase"){
//       const zs = points.map(p => p[2])
//       const partDiff = zs.map(z => zs[0] - z).reduce((a, c) => a + c, 0)
//       diff += partDiff
//     }
//   }
//   return diff
// }

// const direction = (annotations) => {
//   var diff = 0
//   for (const [part, points] of Object.entries(annotations)) {
//     if(part !== "palmBase"){
//       const zs = points.map(p => p[2])
//       const partDiff = zs.map(z => zs[0] - z).reduce((a, c) => a + c, 0)
//       diff += partDiff
//     }
//   }
//   return diff
// }

export default {
  name: 'TensorFlowTest',
  emits: {
    perception: Object
  },
  props: {
    msg: String,
    perceptionEnabled: Boolean
  },
  setup(props, {emit}) {
    console.log(props)

    const videoElement = ref(null)
    const canvas = ref(null)
    onMounted(async () => {
      console.log("mounted")
      console.log(videoElement.value)
      const [video, model] = await Promise.all(
          [
            loadVideo(videoElement.value),
            handpose.load({cache: "force-cache"})
          ]
      )
      console.log(`${video}, ${model}`)
      canvas.value.height = video.videoHeight
      canvas.value.width = video.videoWidth
      const ctx = canvas.value.getContext('2d');
      landmarksRealTime(ctx, video.videoHeight, video.videoWidth, canvas.value, model, video, computed(() => props.perceptionEnabled), (perception) => {
        emit("perception", perception)
      })
    })
    return {
      canvas,
      videoElement
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#canvas-wrapper {
  position: relative;
}
#canvas-wrapper, #scatter-gl-container {
  display: inline-block;
  vertical-align: top;
}
canvas {
  width:100%;
}


</style>
