<template>
  <Card>
    <template v-slot:content>
      <div class="editor" ref="editorContainer"></div>
    </template>
  </Card>
  <Button class="run-button" label="Run" @click="run"/>
</template>

<script>
import { ref, onMounted, watch } from "vue"
import Card from 'primevue/card'
import Button from 'primevue/button'
import { languagePluginLoader } from "../lib/pyodide_local/pyodide"
import * as ace from 'ace-builds';
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/theme-clouds'

function runAndEmit(pyodide, code, emit){
  let stderr = ""
  let res = null
  try{
    res = pyodide.runPython(code)
  }
  catch(e){
    stderr += e.toString()
  }
  const stdout = pyodide.runPython("v = sys.stdout.getvalue(); sys.stdout = io.StringIO(); v")
  emit("stdout", stdout)
  stderr += pyodide.runPython("v = sys.stderr.getvalue(); sys.stderr = io.StringIO(); v")
  if(stderr.length > 0){
    emit("stderr", stderr)
  }
  return res
}

export default {
  name: "Python",
  components: {
    Card,
    Button
  },
  props: {
    perception: Object
  },
  emits: {
    stdout: String,
    stderr: String
  },
  setup(props, { emit }){
    const editorContainer = ref(null)
    const editor = ref(null)
    function run(){
      window.localStorage.setItem("source", editor.value.getValue())
      languagePluginLoader.then((pyodide) => {
        runAndEmit(pyodide, editor.value.getValue(), emit)
      })
    }
    onMounted(() => {
      editor.value = ace.edit(editorContainer.value, {
        mode: "ace/mode/python",
        theme: "ace/theme/clouds",
        fontSize: 16
      });
      if ("source" in window.localStorage){
        editor.value.setValue(window.localStorage.getItem("source"))
      }
      else{
        editor.value.setValue("from functools import reduce\n" +
            "def calcGrabScore(annotations):\n" +
            "  diff = 0\n" +
            "  for part, points in annotations.items():\n" +
            "    if part != \"palmBase\":\n" +
            "      zs = list(map(lambda p: p[2], points))\n" +
            "      partDiff = reduce(lambda a, c: a + c, map(lambda z: zs[0] - z, zs), 0)\n" +
            "      diff += partDiff\n" +
            "  return diff\n" +
            "  \n" +
            "def function(hand):\n" +
            "    score = calcGrabScore(hand[\"annotations\"])\n" +
            "    print(f\"Grasping: {score > 400}\")")
      }
      editor.value.setShowPrintMargin(true)
      editor.value.commands.addCommand({
        name: "execute",
        bindKey: {win: "Ctrl-Enter", mac: "Ctrl-Enter"},
        exec: function() {
          run()
        }
      });
    })
    languagePluginLoader.then((pyodide) => {
      console.log(pyodide.runPython('import sys\nsys.version'));
      pyodide.runPython(`
          import io, code, sys, json
          sys.stdout = io.StringIO()
          sys.stderr = io.StringIO()
          def function(x):
            pass
          def _function(x):
            function(json.loads(x))
        `)
    })
    watch(() => props.perception, perception => {
      languagePluginLoader.then((pyodide) => {
        try{
          const f = runAndEmit(pyodide, "_function", emit)
          f(JSON.stringify(perception))
          runAndEmit(pyodide, "pass", emit)
        }
        catch(e){
          console.log(e)
        }
      })
    })
    return {
      editorContainer,
      run
    }
  }
}
</script>

<style scoped>
.editor {
  width: 100%;
  height: 60vh;
}
.run-button{
  margin: 1em;
}
</style>