<template>
  <div class="p-grid vertical-container">
    <div class="p-col-6 p-md-3 p-lg-3 p-as-center video-container">
      <Card>
        <template v-slot:content>
          <TensorFlowTest v-bind:perceptionEnabled="perceptionEnabled" @perception="perception = $event"/>
        </template>
      </Card>
      <div id="perceptionCheckboxContainer" class="p-field-checkbox">
        <Checkbox id="perceptionCheckbox" v-model="perceptionEnabled" :binary="true">
        </Checkbox>
        <label for="perceptionCheckbox">Perception</label>
      </div>
    </div>
    <div class="p-col-6 p-md-9 p-lg-9 editor-container">
      <Python v-bind:perception="perception" @stdout="pushOutput('outputNormal', $event)" @stderr="pushOutput('outputError', $event)"/>
      <Card>
        <template v-slot:content>
          <Output v-bind:outputs="outputs"></Output>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
// import TensorFlowTest from "@/components/TensorFlowTest";
import Python from "@/components/Python";
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import 'primeflex/primeflex.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import TensorFlowTest from "@/components/TensorFlowTest";
import Output from "@/components/Output";
import {ref} from 'vue'


export default {
  name: 'App',
  components: {
    TensorFlowTest,
    Output,
    // TensorFlowTest,
    Python,
    Card,
    Checkbox
  },
  setup(){
    const perceptionEnabled = ref(false)
    const outputCounter = ref(0)
    const perception = ref(null)
    const outputs = ref([])
    function pushOutput(c, content){
      outputs.value.push({
        class: c,
        content: content,
        key: outputCounter.value
      })
      outputCounter.value++
    }
    return {
      outputs,
      pushOutput,
      perception,
      perceptionEnabled
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.p-card > .p-card-body{
  padding: 0;
}
.p-card .p-card-content{
  padding: 0;
}
.video-container > .p-card{
  overflow: hidden;
}
.editor-container > .p-card{
  overflow: hidden;
}
.errText{
  background-color: red;
}
.outputStream {
  font-family: monospace;
  text-align: left;
}
#perceptionCheckboxContainer{
  margin-top: 1em;
  justify-content: center;
}

</style>
